<script setup lang="ts">
import type { HomeBanners } from "@/types";

const { t } = useT();
const { isMobile } = useDevice();
const { prizePool } = useFreeSpinQuest();
const { open } = useAppModals();

defineProps<{ banner: HomeBanners[number] }>();

const handleClick = () => {
	open("LazyOModalFreeSpin");
};
</script>

<template>
	<client-only>
		<MBanner
			:bg-img="banner.backgroundImage"
			:bg-img2x="banner.backgroundImage2x"
			:bg-color="banner.background"
			:image="banner.image"
			:image-mobile="banner.imageMobile"
			:second-image="banner.secondImage"
			:second-image-mobile="banner.secondImageMobile"
			:type="banner.type"
			@click="handleClick"
		>
			<template #default>
				<AText :size="{ full: 24, md: 14 }" :modifiers="['medium']">
					{{ t("freeSpin.homeBanner.title") }}
				</AText>
			</template>
			<template #description>
				<AText
					:size="{ full: 40, md: 24 }"
					class="text-tlalnepantla text-shadow title"
					:modifiers="['bold', 'uppercase', 'left']"
					as="div"
				>
					<i18n-t keypath="freeSpin.homeBanner.text">
						<template #br>
							<br />
						</template>
						<template #prize>
							<MPrizeFund
								variant="free-spin"
								class="text-shadow text-prize"
								img="/nuxt-img/prizes/freespins.png"
								:icon-size="isMobile ? 20 : 38"
							>
								<AText :size="{ full: 40, md: 24 }" :modifiers="['bold']">
									{{ numberFormat(prizePool ?? 0) }}
								</AText>
								<template #after>
									<AText :size="{ full: 12, md: 10 }" class="text-free" :modifiers="['uppercase', 'bold']">
										<i18n-t keypath="freeSpin.homeBanner.text2">
											<template #br>
												<br />
											</template>
										</i18n-t>
									</AText>
								</template>
							</MPrizeFund>
						</template>
					</i18n-t>
				</AText>
			</template>
			<template #actions>
				<AButton variant="primary" class="button" size="xl">
					<AText :size="{ full: 20, md: 16 }" class="text-tlalnepantla" :modifiers="['semibold']">
						{{ t("freeSpin.homeBanner.btn.name") }}
					</AText>
				</AButton>
			</template>
		</MBanner>
	</client-only>
</template>

<style lang="scss" scoped>
:deep {
	.app-banner {
		&__content {
			width: 100%;
			justify-content: center;
			align-items: flex-start;
			padding: 32px;

			@include media-breakpoint-down(md) {
				padding: 16px;
			}
		}

		&__desc {
			align-items: flex-start;

			@include media-breakpoint-down(md) {
				gap: 0;
			}

			.title {
				@include media-breakpoint-down(sm) {
					display: inline-flex;
					flex-direction: column;

					.prize {
						gap: 4px;
					}
				}
			}

			.text-prize {
				color: var(--tertiary-300);
			}

			.text-free {
				font-family: inherit;
			}
		}

		&__actions {
			margin-top: 16px;
			width: 280px;

			@include media-breakpoint-down(md) {
				margin-top: 12px;
				width: 133px;
			}

			.button {
				width: 100%;

				@include media-breakpoint-down(md) {
					height: 40px;
					border-radius: 8px;
				}
			}
		}
	}
}
</style>
